var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <li class=\"student-modal__item\">\n      <img class=\"student-modal__avatar\" src=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"avatar") || (depth0 != null ? lookupProperty(depth0,"avatar") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"avatar","hash":{},"data":data,"loc":{"start":{"line":10,"column":46},"end":{"line":10,"column":56}}}) : helper)))
    + "\" alt=\"Пользователь: "
    + alias4(((helper = (helper = lookupProperty(helpers,"firstName") || (depth0 != null ? lookupProperty(depth0,"firstName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"firstName","hash":{},"data":data,"loc":{"start":{"line":10,"column":77},"end":{"line":10,"column":90}}}) : helper)))
    + " "
    + alias4(((helper = (helper = lookupProperty(helpers,"secondName") || (depth0 != null ? lookupProperty(depth0,"secondName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"secondName","hash":{},"data":data,"loc":{"start":{"line":10,"column":91},"end":{"line":10,"column":105}}}) : helper)))
    + "\" width=\"60\"\n        height=\"60\">\n      <p class=\"student-modal__name\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"firstName") || (depth0 != null ? lookupProperty(depth0,"firstName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"firstName","hash":{},"data":data,"loc":{"start":{"line":12,"column":37},"end":{"line":12,"column":50}}}) : helper)))
    + " "
    + alias4(((helper = (helper = lookupProperty(helpers,"secondName") || (depth0 != null ? lookupProperty(depth0,"secondName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"secondName","hash":{},"data":data,"loc":{"start":{"line":12,"column":51},"end":{"line":12,"column":65}}}) : helper)))
    + "</p>\n      <p class=\"student-modal__tasks\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"tasks") || (depth0 != null ? lookupProperty(depth0,"tasks") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"tasks","hash":{},"data":data,"loc":{"start":{"line":13,"column":38},"end":{"line":13,"column":47}}}) : helper)))
    + "</p>\n    </li>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"student-modal\">\n\n  <button type=\"button\" class=\"student-modal__close-btn\"></button>\n\n  <h2 class=\"student-modal__form--title\">Наша команда</h2>\n\n  <ul class=\"student-modal__list\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),depth0,{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":4},"end":{"line":15,"column":13}}})) != null ? stack1 : "")
    + "  </ul>\n\n</div>";
},"useData":true});